<template>
    <page-layout>
        <v-container class="fill-height d-flex">
            <v-row no-gutters class="px-6">
                <v-col cols="12" class="nolo-h2">{{ $t('whoops') }}</v-col>
                <v-col cols="12" class="nolo-p1 mt-6 text-center">{{ $t('pageDoesntExist') }}</v-col>
                <v-col cols="12" class="text-center">
                    <i18n tag="div" class="mt-4" path="checkUrl">
                        <router-link to="/">{{ $t('ourHomePage') }}</router-link>
                    </i18n>
                </v-col>
            </v-row>
        </v-container>
    </page-layout>
</template>

<script>
    export default {
        name: "NotFound",
        computed: {
            version() {
                return process.env.VERSION;
            }
        }
    }
</script>

<style scoped>
    .version {
        position: absolute;
        right: 10px;
        bottom: 10px;
    }
</style>

